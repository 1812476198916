import Link from "next/link";
import React from "react";
import { Container } from "@/shared/components/Container";
import { cn } from "@/shared/utils";
import { CustomizationSlot } from "@/shared/components/CustomizationSlot";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { useAppLayerContext } from "../contexts/AppLayer";
import { useDirtyValues } from "@/shared/hooks/useDirtyValues";
import { PartialQuoteSchema, Quote } from "../types/Quote.interface";
import { UIUtils } from "../utils/UIUtils";
import Testimonials from "@/shared/components/Testimonials";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./ui/accordion";

interface SiteFooterProps<StepIdType extends string> {
    formID: string;
    underwriter: PetUnderwriterType;
    links: { label: string; href: string }[];
    copyright: string;
    content?: React.ReactNode;
    extraContent?: React.ReactNode[];
    className?: string;
    // Defaults to false. If true, offsets footer content to avoid overlap with fixed content.
    hasOffset?: boolean;
    currentStep?: StepIdType;
    priorityCode?: string;
    showVideoTestimonials?: boolean;
}

export default function SiteFooter<StepIdType extends string>({
    formID,
    links,
    content,
    className,
    hasOffset = false,
    currentStep,
    extraContent,
    copyright,
    priorityCode,
    showVideoTestimonials
}: SiteFooterProps<StepIdType>) {
    const quote = useAppLayerContext().appState.quoteQuery?.data;
    const { dirtyValues } = useDirtyValues<Quote>();
    const builderData = UIUtils.deepMerge(quote ?? {}, dirtyValues, PartialQuoteSchema).mergedBase as Quote;

    const year = new Date().getFullYear();
    const copyrightText = copyright.replaceAll("%year%", String(year));
    const conditionalClasses = {
        "pb-20 sm:pb-[104px]": hasOffset // offsets fixed price widget so that bottom footer content is not blocked on mobile
    };

    // Statsig - reduced-height
    const { hasReducedHeight } = useAppLayerContext().appState;

    return (
        <>
            {showVideoTestimonials && currentStep === "coverage" && <Testimonials />}
            <CustomizationSlot type="form-footer" data={builderData} formId={formID} formStepId={currentStep} priorityCode={priorityCode} />
            <footer className={cn("inset-x-0 bg-background-primitive--brand-c-800", className, hasReducedHeight ? "pb-4" : "py-4")}>
                <div className={cn("flex flex-col gap-2 text-xs text-content-primitive--white", conditionalClasses, hasReducedHeight ? "text-xs" : "gap-2")}>
                    {links && (
                        <Container>
                            <div className={cn("flex flex-row flex-wrap ", hasReducedHeight ? "hidden gap-4 py-5 md:flex" : "mt-2 gap-6 md:mt-5 md:text-base")}>
                                {links.map((link, index) => (
                                    <Link key={index} href={link.href} target="_blank">
                                        {link.label}
                                    </Link>
                                ))}
                            </div>
                            {hasReducedHeight && (
                                <Accordion type="single" collapsible className="flex w-full flex-col pt-1 md:hidden">
                                    <AccordionItem value="footerLinks">
                                        <AccordionTrigger className="flex justify-between py-5 text-left text-xs font-bold leading-5 text-content-primary-invert">
                                            Important Links
                                        </AccordionTrigger>
                                        <AccordionContent className="flex flex-wrap gap-4 pl-1 pt-1 text-xs text-content-primary-invert">
                                            {links.map((link, index) => (
                                                <Link key={index} href={link.href} target="_blank">
                                                    {link.label}
                                                </Link>
                                            ))}
                                        </AccordionContent>
                                    </AccordionItem>
                                </Accordion>
                            )}
                        </Container>
                    )}

                    <div className={cn("border-b border-stroke-primitive--brand-c-700", hasReducedHeight ? "" : "mb-3 mt-4 md:mb-5 md:mt-3")}></div>

                    <Container className={hasReducedHeight ? "p-4" : "flex flex-col gap-4"}>
                        {content && content}
                        {!!extraContent?.length && extraContent.map((content, index) => <React.Fragment key={`extra-content-${index + 1}`}>{content}</React.Fragment>)}
                        <div className="leading-5">© {copyrightText}</div>
                    </Container>
                </div>
            </footer>
        </>
    );
}
