import { SpotErrorResponse, spotErrorResponseSchema, SpotErrorMessage } from "../types/SpotAPI";
import Strings from "./Strings.constants";
import { isAxiosError, AxiosError } from "axios";

export default class ErrorUtils {
    static getErrorIds(errorResponse: SpotErrorResponse) {
        const errors: SpotErrorMessage[] = [];
        if (errorResponse.error.message.length > 0) {
            errorResponse.error.message.forEach(message => {
                errors.push(message);
            });
        }
        return errors;
    }

    static isSpotApiError(input: unknown): boolean {
        const validationResult = spotErrorResponseSchema.safeParse(input);
        return validationResult.success;
    }

    // Takes in an error response and an error id and returns true if the error response contains the error id
    static hasSpotError(error: AxiosError | unknown, errorId: string): boolean {
        if (isAxiosError(error) && this.isSpotApiError(error.response?.data)) {
            const errorIds = this.getErrorIds(error.response?.data);
            return errorIds.some(err => err.id === errorId);
        }
        return false;
    }

    static ptzZipcodeErrorToSpotError(): SpotErrorResponse {
        const error: SpotErrorResponse = {
            error: {
                code: 400,
                message: [
                    {
                        id: "invalid-postal-code",
                        message: Strings.ERRORS.ZIP
                    }
                ],
                id: "invalid-postal-code"
            }
        };
        return error;
    }

    static flattenFormErrors(errors: Record<string, any>, prefix = ""): Record<string, string> {
        const flattened: Record<string, string> = {};

        Object.entries(errors).forEach(([field, error]) => {
            if (error?.message) {
                flattened[prefix + field] = error.message;
            } else if (Array.isArray(error)) {
                error.forEach((item, index) => {
                    if (typeof item === "object") {
                        const nestedErrors = ErrorUtils.flattenFormErrors(item, `${field}[${index}].`);
                        Object.assign(flattened, nestedErrors);
                    }
                });
            } else if (typeof error === "object" && error !== null) {
                const nestedErrors = ErrorUtils.flattenFormErrors(error, `${field}.`);
                Object.assign(flattened, nestedErrors);
            }
        });

        return flattened;
    }
}
